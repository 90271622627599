<template>
  <div class="diif-page">
    <div class="header">
      <span style="font-size: 24px">基差分析(注意收藏)</span>
    </div>
    <div class="time">
      <span>{{ time }}</span>
    </div>
    <div v-for="group of groupList" :key="group.Name" class="diff-group">
      <el-card>
        <template #header>
          <div>{{ group.diffGrop.Name }}</div>
        </template>
        <el-table
          :data="group.diffList"
          stripe
          style="width: 100%"
          @cell-click="onCellClick"
          :border="true"
        >
          <el-table-column prop="Name" label="产品" width="80" />
          <el-table-column prop="LeftValue" :label="group.diffGrop.LeftLable">
            <template #default="scope">
              <div :class="getValueColor(scope.row, 'LeftOffset')">
                {{ formatLeft(scope.row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="RightValue" :label="group.diffGrop.RightLable">
            <template #default="scope">
              <div :class="getValueColor(scope.row, 'RightOffset')">
                {{ formatRight(scope.row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="DiffValue"
            :label="group.diffGrop.ValueLable"
            :formatter="formatDfiff"
          />
        </el-table>
        <img
          src="/ad.png"
          class="ad"
          v-if="group.ID == 'g001'"
          @click="showAd"
        />
      </el-card>
    </div>
    <div>
      <!-- <el-button text @click="onShowD">click to open the Dialog</el-button> -->

      <el-dialog v-model="dialogVisible" title="Tips" width="100%">
        <span id="chart"></span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import { ElMessageBox } from 'element-plus';

function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

export default {
  name: "DiffPage",
  props: {
    msg: String,
  },
  created() {
    this.loadCache();
    this.ws();
  },
  mounted() {
    console.log(process.env.NODE_ENV);

    setInterval(() => {
      this.time = dateFormat("YYYY-mm-dd HH:MM:SS", new Date());
    }, 1000);
    console.log(window.screen.width);
    window.onresize = () => {
      if (this.dialogVisible) {
        this.$nextTick(() => {
          this.showChart(this.clickRow);
        });
      }
    };
    let host = window.location.host;
    if(host.includes("tengjin168")||host.includes("mz.gold")){
        ElMessageBox.confirm(
            '是否前往新网址查看？',
            '温馨提示',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
            }
          )
            .then(() => {
				window.location.href = "http://jc.manhaworld.com";
            })
            .catch(() => {
            })
    }
  },
  methods: {
    showAd() {
      document.open("https://zx30321224.jzfkw.net/?checkWxLogin=true&openId=TEC9dEd6WHfOkJnWLC%2FVJzbcAQRjta0OCFpki5utK%2Fg%3D&secondAuth=true&appId=wx50775cad5d08d7ad", "", "noopener=true");
    },
    getValueColor(row, vk) {
      const v = row[vk];
      if (v && v > 0) {
        return "value-color-down";
      }
      return "value-color-up";
    },

    getWsAddr() {
      return "ws://47.115.87.76:8885/ws";
      // if (process.env.NODE_ENV === "development") {
      //   return "ws://127.0.0.1:8885/ws";
      // }
      // const isHttps = window.location.protocol === "https:";
      // const wsProtocol = isHttps ? "wss" : "ws";
      // const host = window.location.host;
      // const wsaddr = `${wsProtocol}://${host}/api/ws`;
      // // const wsaddr = "ws://127.0.0.1:8083";
      // return wsaddr;
    },
    getApiAddr() {
      return "http://47.115.87.76:8885";
      // if (process.env.NODE_ENV === "development") {
      //   return "http://127.0.0.1:8885";
      // }
      // return "/api";
    },
    onCellClick(row, column) {
      this.clickRow = row;
      if (column.property == "DiffValue") {
        this.onShowD(row);
      }
    },
    handleClose() {},
    onShowD(diff) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.showChart(diff);
      });
    },

    async showChart(diff, ct) {
      const w = window.screen.width;
      let h = 650;
      if (w < 500) {
        h = 450;
      }
      const config = {
        width: w - 20,
        height: h,
      };
      console.log(config);

      // 基于准备好的dom，初始化echarts实例
      const doc = document.getElementById("chart");
      const myChart = echarts.init(doc, null, config);

      const rsp = await axios.get(this.getApiAddr() + "/list-his", {
        params: {
          name: diff.ID,
          t: "m",
          offset: 0,
          limit: 100,
        },
      });
      const hisList = rsp.data.data;
      const yd = [];
      const xd = [];
      const ydl = [];

      for (const h of hisList) {
        xd.push(h.typeValue);
        yd.push(h.value);
        ydl.push([h.start, h.value, h.max, h.min]);
      }

      let series = [
        {
          data: yd.reverse(),
          type: "line",
        },
      ];

      if (ct != 2) {
        series = [
          {
            type: "candlestick",
            data: ydl,
          },
        ];
      }

      const option = {
        xAxis: {
          type: "category",
          data: xd.reverse(),
        },
        yAxis: {
          type: "value",
          scale: true,
        },
        series,
      };

      // 绘制图表
      myChart.setOption(option);

      myChart.resize(config);
    },
    loadCache() {
      axios.get(this.getApiAddr() + "/state-diff").then((res) => {
        // alert(JSON.stringify(res.data));
        this.setupTable(res.data.data);
      });
    },

    formatDfiff(row) {
      if (row.Decimal != undefined) {
        return row.DiffValue.toFixed(row.Decimal);
      }
      return row.DiffValue.toFixed(3);
    },

    formatLeft(row) {
      if (row.Decimal != undefined) {
        return row.LeftValue.toFixed(row.Decimal);
      }
      return row.LeftValue.toFixed(3);
    },

    formatRight(row) {
      if (row.Decimal != undefined) {
        return row.RightValue.toFixed(row.Decimal);
      }
      return row.RightValue.toFixed(3);
    },

    ws() {
      const conn = new WebSocket(this.getWsAddr());
      conn.onmessage = this.onmessage;

      conn.onclose = () => {
        setTimeout(() => this.ws(), 1000 * 10);
      };
    },
    onmessage(msg) {
      const data = JSON.parse(msg.data);
      for (const group of this.groupList) {
        if (data.Group == group.diffGrop.Name) {
          for (const diff of group.diffList) {
            if (diff.Name == data.Name) {
              try {
                diff.LeftOffset = data.LeftValue - diff.LeftOffset;
                diff.RightOffset = data.RightValue - diff.RightValue;
              } catch (e) {
                console.error(e);
              }
              diff.LeftValue = data.LeftValue;
              diff.RightValue = data.RightValue;
              diff.DiffValue = data.DiffValue;
            }
          }
        }
      }
    },
    setupTable(data) {
      const groupList = [];
      for (const key in data) {
        const group = {};
        group.diffGrop = data[key].Group;
        group.diffList = data[key].DiffList;
        group.diffList = group.diffList.sort((a, b) => {
          if (a.ID < b.ID) {
            return -1;
          }
          return 1;
        });

        group.ID = data[key].Group.ID;
        groupList.push(group);
      }
      this.groupList = groupList.sort((a, b) => {
        if (a.ID < b.ID) {
          return -1;
        }
        return 1;
      });
    },
  },
  data() {
    return {
      dialogVisible: false,
      groupList: [],
      clickRow: {},
      time: "--",
      is_old: false
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.value-color-up {
  color: red;
}
.value-color-down {
  color: green;
}
::v-deep th {
  font-weight: 400;
  font-size: 18px;
}
::v-deep .el-table {
  font-size: 18px;
}
::v-deep .el-card__header {
  padding: 10px !important;
  text-align: center;
}

::v-deep .el-card__body {
  padding: 0px;
}

.header {
  margin-bottom: 10px;
}

.diff-group {
  margin-bottom: 35px;
}

.diif-page {
  width: 100%;
  max-width: 1350px;
}

.diif-title {
  margin-bottom: 20px;
}

#chart {
  width: 1920px;
  height: 650px;
  /* position: relative; */
}

.time {
  text-align: right;
  /* padding-right: 3px; */
  padding-bottom: 10px;
  /* margin-bottom: 20px; */
}
.ad {
  width: 100%;
  max-width: 450px;
  height: 100px;
}
</style>
